

h1, h2, h3, span, p, a{
  letter-spacing: normal;
}

.contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 30px 30px 30px;
}

.mainHeader {
  border-bottom: 0px solid var(--main-color);
  border-left: 6px solid var(--main-color);
  border-right: 0px solid var(--main-color);
  border-top: 0px solid var(--main-color);
  padding: 5px 10px 5px 30px;
}

.mainHeader h2{
  font-family: 'Libre Baskerville', serif;
  margin: 0;
  font-size: 32px;
  font-weight: normal;
}
.mainHeader h3{
  margin: 0;
  font-size: 24px;
  font-weight: normal;
}
.contents p{
  font-size: 18px;
  margin: 0;
}

/* メディアクエリ */
@media (max-width: 848px) {
  /* .contents {
    padding: 30px 30px 100px 30px;
  } */
  .mainHeader h2{
    font-size: 28px;
  }
  .mainHeader h3{
    font-size: 20px;
  }
}
/* メディアクエリ */
@media (max-width: 600px) {
  .mainHeader h2{
    font-size: 20px;
  }
  .mainHeader h3{
    font-size: 14px;
  }
  .mainHeader {
    padding-left: 10px;
  }
  .contents p{
    font-size: 12px;
    margin: 0;
  }
  /* .contents {
    padding: 15px 15px 100px 15px;
  } */
}