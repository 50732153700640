:root {
  --english: 'Libre Baskerville';
  --japanese: 'Noto Sans JP', sans-serif;
  --japanese-stylish: 'Noto Serif JP', serif;
  --main-color: rgb(14, 55, 91)
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #333;
}

h1, h2, h3, span, p, a{
  font-family: var(--japanese);
  letter-spacing: .1em;
}

.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime{
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}