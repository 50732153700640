/* MyComponent.module.css */
.container {
  padding: 0 50px 0 50px;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 30px;
}

.item {
  border-bottom: 1px solid #000;
  padding: 16px 0;
}

.labelValueContainer {
  display: flex;
  align-items: center;
}

.label {
  width: 80px; /* Adjust the width as needed */
  margin-right: 24px; /* Fixed spacing between label and value */
  color: var(--main-color);
  font-weight: bold;
}

.value {
  min-width: 160px; /* Adjust minimum width as needed */
}

.businessDetails {
  display: flex;
  flex-direction: column;
}

.mapDetails {
  display: flex;
  gap: 20px;
  align-items: center;
}
.mapDetails .value {
  min-width: 320px;
}

.map {
  height: 300px;
}

@media (max-width: 600px) {
  .labelValueContainer {
     flex-direction: column;
     align-items: flex-start;
     font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .mapDetails {
    flex-direction: column;
    align-items: flex-start;
  }
}