
.fixed {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 1;
}
.headerPadding {
  padding-bottom: 98px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 30px 20px 30px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px
}

.logo span{
  font-size: 32px;
  font-family: var(--english);
  letter-spacing: normal;
  color: var(--main-color);
}

.logo img {
  height: 50px; /* ロゴの高さ */
}

/* ハンバーガーメニューのスタイル */
.navToggle {
  display: none;
  cursor: pointer;
  width: 30px;
}

.hamburger,
.hamburger::before,
.hamburger::after {
  display: block;
  background-color: #fff;
  height: 3px;
  width: 25px;
  border-radius: 3px;
  position: relative;
  transition: all .3s ease-in-out;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
}

.hamburger::before {
  top: -8px;
}

.hamburger::after {
  top: 8px;
}

/* ナビゲーションリストのスタイル */
.nav {
  display: flex;
}

.navList {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 24px;
}

.descriptionBackground {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  width: 600px;
  height: 100%;
  background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0) 210px, rgba(14, 55, 91, 0.1) 210px, rgba(14, 55, 91, 0.6));
  background-image:-webkit-linear-gradient(-45deg, rgba(0, 0, 0, 0) 210px, rgba(14, 55, 91, 0.1) 210px, rgba(14, 55, 91, 0.6));
  background-image:-moz-linear-gradient(-45deg, rgba(0, 0, 0, 0) 210px, rgba(14, 55, 91, 0.1) 210px, rgba(14, 55, 91, 0.6));
  background-image:-ms-linear-gradient(-45deg, rgba(0, 0, 0, 0) 210px, rgba(14, 55, 91, 0.1) 210px, rgba(14, 55, 91, 0.6));
}

.headerImageBackground {
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.headerImage {
  width: 100%;
}

.haederTitleEn {
  color: #fff;
  font-size: 32px;
  font-family: var(--english);
  margin: 0;
}

.headerSubJp {
  color: #fff;
  font-size: 24px;
  margin: 0;
}
/* メディアクエリ */
@media (max-width: 899px) {
  /* ハンバーガーメニューを表示 */
  .navToggle {
    display: block;
  }

  /* ナビゲーションリンクを非表示にする */
  .nav {
    flex-direction: column;
    align-items: center;
    background-color: var(--main-color);
    display: none;
  }

  /* ナビゲーションリストのスタイル調整 */
  .navList {
    flex-direction: column;
    width: 100%;
    padding: 40px 20px;
  }

  .navItem {
    margin: 10px 0;
    width: 100%;
  }

  .navItem a div {
    width: 100%;
  }
  .navItem a div span {
    color: #fff;
   }

  /* メニューが表示されている場合のスタイル */
  .navVisible {
    display: flex;
  }
}

/* 848px以上の場合のスタイル */
@media (min-width: 900px) {
  /* ナビゲーションリンクを表示 */
  .nav {
    display: flex;
  }

  /* ハンバーガーメニューを非表示にする */
  .navToggle {
    display: none;
  }
}

@media (max-width: 600px) {
  .logo span{
    font-size: 24px;
  }
  .logo img{
    height: 30px;
  }
  .header {
    padding: 14px 15px 10px 15px;
  }
  .headerPadding {
    padding-bottom: 54px;
  }
  .navToggle {
    width: 20px;
  }
  .descriptionBackground {
    width: 330px;
    padding-left: 20px;
    background-image: linear-gradient(-60deg, rgba(0, 0, 0, 0) 210px, rgba(14, 55, 91, 0.1) 210px, rgba(14, 55, 91, 0.6));
    background-image:-webkit-linear-gradient(-60deg, rgba(0, 0, 0, 0) 210px, rgba(14, 55, 91, 0.1) 210px, rgba(14, 55, 91, 0.6));
    background-image:-moz-linear-gradient(-60deg, rgba(0, 0, 0, 0) 210px, rgba(14, 55, 91, 0.1) 210px, rgba(14, 55, 91, 0.6));
    background-image:-ms-linear-gradient(-60deg, rgba(0, 0, 0, 0) 210px, rgba(14, 55, 91, 0.1) 210px, rgba(14, 55, 91, 0.6));
  }
  .haederTitleEn {
    font-size: 24px;
  }
  
  .headerSubJp {
    font-size: 16px;
  }
}
.select {
  color: #0097ac !important;
}
