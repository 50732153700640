/* スタイルの基本設定 */
*, *::before, *::after {
  box-sizing: border-box;
}

.headerImageBackground {
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.contentDescription{
  text-align: center;
  color: var(--main-color);
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: bold;
}
.contentDescriptionThin {
  text-align: center;
  color: var(--main-color);
  font-size: 18px;
  margin-bottom: 30px;
}
.contentDescription p{
  margin:0;
}

.descriptionBackground {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  width: 600px;
  height: 100%;
  padding-bottom: 100px;
}
.headerSubJp {
  color: #000;
  font-size: 24px;
  margin: 0;
}
.haederTitleEn {
  color: #000;
  font-size: 32px;
  font-family: var(--english);
  margin: 0;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #006400;
  padding: 10px 20px;
}

.logo img {
  height: 50px; /* ロゴの高さ */
}

/* ハンバーガーメニューのスタイル */
.navToggle {
  display: none;
  cursor: pointer;
}

.hamburger,
.hamburger::before,
.hamburger::after {
  display: block;
  background-color: #fff;
  height: 3px;
  width: 25px;
  border-radius: 3px;
  position: relative;
  transition: all .3s ease-in-out;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
}

.hamburger::before {
  top: -8px;
}

.hamburger::after {
  top: 8px;
}

/* ナビゲーションリストのスタイル */
.nav {
  display: flex;
}

.navList {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navItem {
  margin-left: 20px; /* ナビゲーション項目間の余白 */
}

.navLink {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

/* メディアクエリ */
@media (max-width: 848px) {
  /* ハンバーガーメニューを表示 */
  .navToggle {
    display: block;
  }

  /* ナビゲーションリンクを非表示にする */
  .nav {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: center;
    background-color: #006400;
    display: none;
  }

  /* ナビゲーションリストのスタイル調整 */
  .navList {
    flex-direction: column;
    width: 100%;
  }

  .navItem {
    margin: 10px 0;
  }

  /* メニューが表示されている場合のスタイル */
  .navVisible {
    display: flex;
  }
}

/* 848px以上の場合のスタイル */
@media (min-width: 849px) {
  /* ナビゲーションリンクを表示 */
  .nav {
    display: flex;
  }

  /* ハンバーガーメニューを非表示にする */
  .navToggle {
    display: none;
  }
}

.category {
  background: var(--main-color);
  color: #fff;
  margin-left: 10px; 
  font-size: 10px;
  padding: 0 5px 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* レスポンシブ対応 */
@media screen and (max-width: 848px) {
  hr {
    margin-top: 10px;
  }
}
.responsiveImageContainer {
  padding-top: 100px;
  width: 100%; /* コンテナの横幅を画面いっぱいに */
  overflow: hidden; /* はみ出た画像を切り取る */
}

.responsiveImage {
  width: 100%; /* 画像の横幅をコンテナいっぱいに */
  height: auto; /* 高さは自動でアスペクト比を保持 */
  object-fit: cover; /* 画面サイズが小さい時は中心を切り抜く */
}
.imageTextContainer .responsiveImage {
  border-radius: 15px;
}
/* 大きい画面サイズのときのスタイル */
@media screen and (max-width: 1280px) {
  .responsiveImage {
    object-fit: contain; /* 画像のアスペクト比を保ちながら表示 */
  }

  .category {
    font-size: 1.145vw;
  }

}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.imageItem {
  width: 100%;
  margin-bottom: 20px;
}

@media (min-width: 848px) {
  .imageItem {
    width: calc(50% - 10px);
  }
}

/* ImageText.module.css */
.imageTextContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 40%;
  max-width: 525px;
}

@media (max-width: 848px) {
  .imageTextContainer {
    width: 80%; /* 2列で表示するための幅調整、余白を含む */
  }
}

.responsiveImage {
  width: 100%; /* 画像の横幅をコンテナいっぱいに */
  height: auto; /* 高さは自動でアスペクト比を保持 */
  display: block;
  object-fit: cover; /* 画面サイズが小さい時は中心を切り抜く */
}

.imageText {
  margin-top: 10px; /* 画像とテキストの余白 */
}
.imageText p {
  margin: 0;
}
.imageText-enp {
  font-size: 24px;
  font-family: var(--english);
  letter-spacing: normal;
}

.imageTextWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  margin-bottom: 40px;
}

.toContact {
  border: none;
  background: var(--main-color);
  border-radius: 12px;
  color: #333;
  flex: none;
  font-weight: 400;
  height: 66px;
  justify-content: center;
  letter-spacing: 0em;
  line-height: 1.4;
  margin: auto;
  opacity: 1;
  padding: 15px;
  text-align: left;
  width: auto;
  max-width: 100%;
}

.toContact a {
  border-radius: 0;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 700;
  height: 100%;
  letter-spacing: 0em;
  line-height: 1;
  margin: 0 1rem;
  opacity: 1;
  padding: 0px 0px 2px 0px;
  text-align: center;
  width: 100%;
  max-width: 100%;
  justify-content: center;
}

.footerContainer {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5; /* または適切な背景色に変更してください */
  padding: 20px;
}

.footerLogo {
  height: 50px; /* ロゴの高さ */
  margin-right: 10px;
}
.footerLogoText {
  font-size: 24px;
  color: #333; /* またはブランドに合った色に変更してください */
}

.footerNav {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footerNavLink {
  text-decoration: none;
  color: #333; /* リンクの色 */
}

@media (max-width: 848px) {
  .footerContainer {
    display: none;
  }
}


@media (max-width: 600px) {
  .haederTitleEn {
    font-size: 24px;
  }
  
  .headerSubJp {
    font-size: 16px;
  }
  .descriptionBackground{
    padding-left: 20px;
    width: 330px;
  }
}