.container {
  padding-top: 30px;
  max-width: 800px;
  margin: auto;
  padding-bottom: 100px;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputGroup {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.label {
  font-size: 18px;
  font-weight: bold;
  margin-right: 8px;
  flex: 0.5; /* Makes sure the label flexes to take up space */
}

.input,
.textarea {
  flex: 2; /* Allows input fields to take twice the space of labels */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.checkboxList {
  flex: 2; /* Allows input fields to take twice the space of labels */
  display: flex;
  gap: 10px;
}

.checkboxItem {
  display: flex;
}
.input {
  min-height: 50px;
}

.textarea {
  min-height: 150px;
  resize: vertical;
  flex: 2; /* Overrides the flex-grow value for text area */
}

.submitButton {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: flex-start; /* Aligns the submit button to the start */
}

.submitButton:hover {
  background-color: #0056b3;
}

/* Responsive styling */
@media (max-width: 848px) {
  .checkboxList {
    flex-direction: column;
  }
  .inputGroup {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    align-items: stretch; /* Resets alignment to default for mobile */
  }

  .label {
    margin: 0 0 8px 0;
  }

  .input,
  .textarea {
    padding: 4px;
  }

  .submitButton {
    align-self: stretch; /* Makes submit button width 100% on mobile */
  }
}

.submit {
  background: var(--main-color);
  border-radius: 24px;
  box-shadow: 3px 3px rgba(0,0,0,0.2);
  color: #333;
  flex: none;
  font-size: 15px;
  font-weight: 400;
  height: 66px;
  justify-content: center;
  letter-spacing: 0em;
  line-height: 1.4;
  margin: auto;
  opacity: 1;
  padding: 15px;
  text-align: left;
  width: auto;
  max-width: 100%;
}

.submit span {
  border-radius: 0;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 700;
  height: 100%;
  letter-spacing: 0em;
  line-height: 1;
  margin: 0 0 0 0;
  opacity: 1;
  padding: 0px 0px 2px 0px;
  text-align: center;
  width: 100%;
  max-width: 100%;
  justify-content: center;
}
