
.contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 30px 30px 30px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, calc(33%)); /* 列幅を300pxの固定値に変更 */
  gap: 16px; /* アイテム間のギャップ */
  justify-content: space-between; /* アイテムを中央寄せ */
  grid-auto-rows: auto; 
  padding: 0 30px 0 30px;
}

.item {
  border: 1px solid #ccc;
  padding: 16px;
  padding-top: 30px; /* colorBandの高さ + 内側のパディング */
  box-sizing: border-box;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  font-size: 14px;
}

.colorBand {
  height: 25px; /* 調整可能な高さ */
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, rgb(28,36,45) 0%, rgb(20,83,135) 100%);
}

.section, .subSection {
  padding: 8px 0 8px 0;
}
.title {
  font-size: 18px;
}

.section:not(:last-child), .subSection:not(:last-child) {
  border-bottom: 1px solid #ddd;
  margin-bottom: 8px;
}

.subSection {
  display: flex;
  gap: 20px;
  /* justify-content: space-between; */
}

.company, .country {
  /* flex: 1; */
}

@media screen and (max-width: 1200px) {
  .grid {
    grid-template-columns: repeat(2, calc(50%)); /* 列幅を300pxの固定値に変更 */
  }
}

@media screen and (max-width: 800px) {
  .grid {
    grid-template-columns: auto
  }
}

