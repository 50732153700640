

h1, h2, h3, span, p, a{
  letter-spacing: normal;
}

.line {
  border: none;
  height: 1px;
  background-color: #ccc;
  margin-top: 20px;
}
.container {
  letter-spacing: .2em;
  margin: 0 auto;
  padding: 4.6875% 0 2.3437% 0;
  width: 92.1875%;
  display: flex;
  gap: 50px;
}
.newsItem {
  margin-bottom: 20px;
  max-width: 1000px;
}
.contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 70px 30px 30px 30px;
}

.mainHeader {
  border-bottom: 0px solid var(--main-color);
  border-left: 6px solid var(--main-color);
  border-right: 0px solid var(--main-color);
  border-top: 0px solid var(--main-color);
  padding: 5px 10px 5px 30px;
}

.mainHeader h2{
  font-family: 'Libre Baskerville', serif;
  margin: 0;
  font-size: 32px;
  font-weight: normal;
}
.mainHeader h3{
  margin: 0;
  font-size: 24px;
  font-weight: normal;
}
.contents p{
  font-size: 18px;
  margin: 0;
}

/* メディアクエリ */
@media (max-width: 848px) {
  .contents {
    padding: 30px 30px 100px 30px;
  }
  .mainHeader h2{
    font-size: 28px;
  }
  .mainHeader h3{
    font-size: 20px;
  }
}
/* メディアクエリ */
@media (max-width: 600px) {
  .mainHeader h2{
    font-size: 20px;
  }
  .mainHeader h3{
    font-size: 14px;
  }
  .mainHeader {
    padding-left: 10px;
  }
  .contents p{
    font-size: 12px;
    margin: 0;
  }
  .contents {
    padding: 15px 15px 100px 15px;
  }
}

.newsSidebar {
  padding-right: 20px; /* タイトルとコンテンツの間の余白 */
  margin-left: 60px;
}

.newsTitleJp {
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #333;
  font-size: 22px;
  font-family: var(--japanese-stylish);
  letter-spacing: .2em;
}

.newsTitleEn {
  font-family: var(--english);
  margin-top: 0;
  font-size: 12px;
  color: #333;
  padding-bottom: 8px; /* 英語タイトルの下の余白 */
  letter-spacing: .2em;
}

.newsContent {
  flex: 3;
}

.newsSummary {
  color: #666;
  margin-top: 5px;
  font-size: 16px;
}


.newsDate {
  font-weight: normal;
  color: var(--main-color);
  margin: 0;
  font-size: 16px;
}

/* レスポンシブ対応 */
@media screen and (max-width: 848px) {
  .newsSidebar {
    text-align: center;
    padding-right: 0;
  }

  .newsContent {
    flex: 1;
  }

  .newsItem {
    margin-bottom: 20px;
  }
  .container {
    flex-direction: column;
    gap: 0;
  }
}
/* 大きい画面サイズのときのスタイル */
@media screen and (max-width: 1280px) {
  .newsTitleJp {
    font-size: 1.735vw;
  }
  .newsTitleEn {
    font-size: 1.2vw;
  }
  .newsDate {
    font-size: 1.345vw;
  }
}

