

h1, h2, h3, span, p, a{
  letter-spacing: normal;
}

.contents {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 30px 100px 30px;
}

.mainHeader {
  border-bottom: 0px solid var(--main-color);
  border-left: 6px solid var(--main-color);
  border-right: 0px solid var(--main-color);
  border-top: 0px solid var(--main-color);
  padding: 5px 10px 5px 30px;
}

.mainHeader h2{
  font-family: 'Libre Baskerville', serif;
  margin: 0;
  font-size: 32px;
  font-weight: normal;
}
.profileList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)); /* 300pxの最小幅で列を自動配置 */
  gap: 50px; /* アイテム間のスペース */
  justify-content: center; /* アイテムをグリッド領域の開始点に揃える */
  padding: 0 30px 0 30px;
}

.profile {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.profileList::after{
  content:"";
  display: block;
  width:300px;
}
.profile p {
  margin: 0;
}
/* メディアクエリ */
@media (max-width: 800px) {
  .profileList {
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr)); /* 300pxの最小幅で列を自動配置 */
  }
  .contents {
    padding: 30px 0px 100px 0px;
  }
}
@media (max-width: 848px) {
  .mainHeader h2{
    font-size: 28px;
  }
}


/* メディアクエリ */
/* @media (max-width: 600px) {
  .mainHeader h2{
    font-size: 20px;
  }
  .mainHeader {
    padding-left: 10px;
  }
  .contents {
    padding: 15px 15px 100px 15px;
  }
} */